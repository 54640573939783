


















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class AppTemplate extends Vue {
  @Prop({ required: true }) closed!: boolean
  @Prop({ required: true }) delayedClose!: boolean
  @Prop({ required: true }) appIndex!: number
  @Prop({ required: false, default: true }) appTitle!: string

  freezeAppIndex: null | number = null

  get classString (): string {
    let classes = ''

    if (this.freezeAppIndex) classes += ` app--index-${this.freezeAppIndex}`
    if (this.closed) classes += ' app--close'
    if (this.delayedClose) classes += ' app--hide'

    return classes
  }

  appWindowClick() {
    this.incrementZIndex()
    this.$emit('appClick')
  }

  // Makes sure the app the user is interacting with is on top layer
  incrementZIndex() {
    // @ts-ignore
    const selfZIndex = Number(this.$el.style.zIndex)
    const apps = document.querySelectorAll('.app')
    let maxZindex = -1
    
    // Find highest app z index
    for (let app of apps) {
      // @ts-ignore
      const appZIndex = Number(app.style.zIndex)
      if (appZIndex > maxZindex) maxZindex = appZIndex
    }

    // If app does not have or has one lower then max
    // then set app z-index as an increment of max zIndex
    if (selfZIndex === 0 || selfZIndex < maxZindex) {
      // @ts-ignore
      this.$el.style.zIndex = String(maxZindex + 1)
    }
  }

  mounted() {
    this.incrementZIndex()
  }

  @Watch('closed')
  updateAppIndex(isClosed: boolean) {
    if (isClosed) {
      this.freezeAppIndex = null
    } else {
      this.freezeAppIndex = this.appIndex
      this.incrementZIndex()
    }
  }
}
