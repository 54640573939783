





















import { Component, Vue } from 'vue-property-decorator';
// Components
import DesktopIcon from '../components/desktopIcon.vue';
import TerminalApp from '../components/apps/terminal.vue';
import EmailApp from '../components/apps/email.vue';

@Component({
  components: {
    DesktopIcon,
    TerminalApp,
    EmailApp
  },
})
export default class Desktop extends Vue {
  openApps: string[] = []

  openApp (appName: string): void {
    if (this.openApps.indexOf(appName) !== -1) return

    Vue.set(this.openApps, this.openApps.length, appName)
  }

  closeApp (appName: string): void {
    const appIndex = this.openApps.indexOf(appName)
    if (appIndex === -1) return

    Vue.delete(this.openApps, appIndex)
  }
}
