

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { COMMAND_TEXTS } from './commandTexts';

@Component({})
export default class Commands extends Vue {
    @Prop({ required: true }) command!: string;

    forbiddenCommands = [
        'cd',
        'pwd',
        'cat',
        'mv',
        'mkdir',
        'rmdir',
        'rm',
        'touch',
        'locate',
        'find',
        'grep',
        'sudo',
        'df',
        'du',
        'head',
        'tail',
        'diff',
        'tar',
        'chmod',
        'chown',
        'jobs',
        'wget',
        'uname',
        'top',
        'history',
        'man',
        'echo',
        'zip',
        'unzip',
        'hostname',
        'useradd',
        'userdel',
    ]

    actions: { [index: string]: Function } = {
        // Functional
        '--help': this.help,
        'clear': this.clear,
        'ls': () => {  this.content(COMMAND_TEXTS.ls) },
        'quit': this.quit,
        'kill': this.quit,
        'forbidden': this.forbidden,
        'cp': this.copy,
        'ping': this.ping,
        // Content
        'about': () => { this.content(COMMAND_TEXTS.about) },
        'links': () => { this.content(COMMAND_TEXTS.links) },
        'education': () => { this.content(COMMAND_TEXTS.education) },
        'experience': () => { this.content(COMMAND_TEXTS.experience) },
        'tech': () => { this.content(COMMAND_TEXTS.tech) },
        'projects': () => { this.content(COMMAND_TEXTS.projects) },
        'hobbies': () => { this.content(COMMAND_TEXTS.hobbies) },
    }

    @Watch('command', { immediate: true })
    onCommand(newValue: string) {
        if (newValue === '&nbsp;' || newValue === '') return
        const newValuePieces = newValue.split('&nbsp;')

        const forbiddenCommands = this.forbiddenCommands.indexOf(newValuePieces[0])
        if (forbiddenCommands !== -1) {
            this.forbidden(newValue)
        } else if (this.actions[newValuePieces[0]]) {
            this.$store.commit('ADD_LINE', { carret: true, type: 'log', text: newValue })
            this.actions[newValuePieces[0]](newValue, newValuePieces)
        } else {
            this.notFound(newValue)
        }

        this.$emit('done')
    }

    content(text: string, type: string = 'log') {
        this.$store.commit('ADD_LINE', { carret: false, type: 'log', text })
    }

    // COMMANDS
    help() {
        this.$store.commit('ADD_LINE', { carret: false, type: 'log', text: COMMAND_TEXTS.help })
    }

    clear() {
        this.$store.commit('CLEAR')
    }

    notFound (command: string) {
        this.$store.commit('ADD_LINE', { carret: false, type: 'error', text: `command not found > '${ command }'` })
        this.$store.commit('ADD_LINE', { carret: false, type: 'warning', text: COMMAND_TEXTS.notFound })
    }

    forbidden (command: string) {
        this.$store.commit('ADD_LINE', { carret: false, type: 'error', text: `unauthorized command > '${ command }'` })
        this.$store.commit('ADD_LINE', { carret: false, type: 'warning', text: COMMAND_TEXTS.forbidden })
    }

    ping(newValue: string, newValuePieces: string[]): void {
        if (newValuePieces[1]) {
            let target = newValuePieces[1]
            const prefix = 'https://'
            if (target.indexOf(prefix) !== 0) target = `${prefix}${target}`
            window.location.href = target
        } else {
            this.$store.commit('ADD_LINE', { carret: false, type: 'error', text: `Missing argument --address` })
        }
    }

    quit() {
        this.$emit('close')
    }

    copy() {
        this.$emit('copy')
        this.$store.commit('ADD_LINE', { carret: false, type: 'success', text: `Terminal text copied to your clipboard` })
    }
}
