









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class DesktopIcon extends Vue {
    @Prop({ required: true }) label!: string
    @Prop({ required: true }) icon!: string
    @Prop({ required: true }) iconIndex!: number
}
