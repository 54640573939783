
export enum COMMAND_TEXTS {
    firstLine = `<pre class="line--success">
    ____.____________________            .___             
   |    |\\_   _____/\\_   ___ \\  ____   __| _/____   ______
   |    | |    __)  /    \\  \\/ /  _ \\ / __ |/ __ \\ /  ___/
/\\__|    | |     \\   \\     \\___(  <_> ) /_/ \\  ___/ \\___ \\ 
\\________| \\___  /    \\______  /\\____/\\____ |\\___  >____  >
              \\/            \\/            \\/    \\/     \\/ 
</pre>
    <span class="line--warning">
    Welcome to JFCodes CvCLI (curriculum vitae command line interface).</br>My name is Joaquim Fonseca. I have a Masters degree in Industrial Engineering and Management but slowly drifted my professional carrer into Programming. This website is how I present myself.</br>
    Type --help for the list of actions<br/>
    </br>
    </span>
    `,
    notFound = `Type '--help' for the list of actions available on JFCodes CLI</br>`,
    forbidden = `User 'recruiter' does not have priveligies to execute this command</br>`,
    ls = `./notFound.jcodes
    &nbsp;&nbsp;&nbsp ./forbidden.jcodes
    &nbsp;&nbsp;&nbsp ./help.jcodes
    &nbsp;&nbsp;&nbsp ./about.jcodes
    &nbsp;&nbsp;&nbsp ./links.jcodes
    &nbsp;&nbsp;&nbsp ./education.jcodes
    &nbsp;&nbsp;&nbsp ./experience.jcodes
    &nbsp;&nbsp;&nbsp ./tech.jcodes
    &nbsp;&nbsp;&nbsp ./projects.jcodes
    &nbsp;&nbsp;&nbsp ./hobbies.jcodes
    &nbsp;&nbsp;&nbsp ./clear.jcodes
    &nbsp;&nbsp;&nbsp ./quit.jcodes
    </br></br>`,
    help = `</br>
    Usage: &lt;command&gt;</br>
    </br>
    Commands:</br>
    &nbsp;&nbsp;&nbsp;about&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-> A short presentation about myself</br>
    &nbsp;&nbsp;&nbsp;links&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-> Links for my social networks and how to contact me</br>
    &nbsp;&nbsp;&nbsp;education&nbsp;&nbsp;&nbsp;&nbsp;-> Describes my education timeline</br>
    &nbsp;&nbsp;&nbsp;experience&nbsp;&nbsp;&nbsp;-> Professional activity since graduation to the present day</br>
    &nbsp;&nbsp;&nbsp;tech&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-> Non exhaustive list of code languages, frameworks and tools</br>
    &nbsp;&nbsp;&nbsp;projects&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-> Projects I have developed out of scope of day-to-day work</br>
    &nbsp;&nbsp;&nbsp;hobbies&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;-> What I do when I am not working</br>
    </br>`,
    about = `</br>
    Hi, my name is Joaquim Fonseca, born in 89 and working as a Front-end Developer at <a href="https://www.salsify.com/" target="_blank">Salsify</a>.</br>
    </br>
    Experienced with a demonstrated history of working in the information technology and services industry. Skilled in Programming and Engineering. Strong entrepreneurship spirit with an Engineer's Degree focused in Industrial Management from Universidade Nova de Lisboa. 
    </br>
    </br>Some of my strengths are:</br>
    <span class="line--success">
    -> Always eager to learn new technologies, frameworks and methodologies</br>
    -> Attention to detail, code integrity and project structure</br>
    -> Highly motivated working on new, innovative projects</br>
    -> Conscious how small-local decisions may affect future code improvements and maintenance</br>
    -> Fast learner and problem solving skills</br>
    -> Capable of managing the full project lifecycle with constant contact with clients</br>
    </span>
    </br>And some of my weaknesses:</br>
    <span class="line--error">
    -> May get lost and spend time and effort on non added value details (like this website)</br>
    -> Supporter of redoing better instead of maintaining legacy</br>
    -> Low motivation on repetitive tasks - will script it if possible</br>
    -> Annoying with new ideas and suggestions, but always respects authority decisions</br>
    -> Needs good web designer to do great and fancy web design stuff</br>
    </span>
    </br>
    I write and talk native Portuguese and I am fluent (oral and written) in English. 
    </br>`,
    links = `</br>
    Some useful links you might want to check:</br>
    </br>
    &nbsp;&nbsp;&nbsp; -> My <a href="https://www.linkedin.com/in/jfcodes/" target="_blank">LinkedIn profile</a>.</br>
    &nbsp;&nbsp;&nbsp; -> Projects at <a href="https://github.com/JFCodes" target="_blank">Github JFCodes</a>.</br>
    &nbsp;&nbsp;&nbsp; -> My email <a href="mailto:jpcc.fonseca@gmail.com" target="_blank">jpcc.fonseca at gmail.com</a>.</br>
    </br>`,
    education = `</br>
    Sep 2007 <-> Sep 2012</br>
    &nbsp;&nbsp;&nbsp;Masters Degree in Industrial Engineering and Management in Science and Technology, Faculty of New University of Lisbon. Finished with outstanding classification and earned a Merit Scholarship for the performance relative to the fourth year.</br>
    </br>
    Sep 2012 <-> Feb 2014</br>
    &nbsp;&nbsp;&nbsp;PhD. Student in Production Engineering at Science and Technology, Faculty of New University of Lisbon. Focused on external projects and did not conclude the curriculum.</br>
    </br>`,
    experience = `</br>
    Sep 2007 <-> Sep 2012</br>
    &nbsp;&nbsp;&nbsp;Masters Degree in Industrial Engineering and Management in Science and Technology, Faculty of New University of Lisbon</br>
    </br>
    Sep 2012 <-> Feb 2014</br>
    &nbsp;&nbsp;&nbsp;PhD. Student in Production Engineering at Science and Technology, Faculty of New University of Lisbon</br>
    </br>
    Apr 2014 <-> Apr 2015</br>
    &nbsp;&nbsp;&nbsp;Partner & Founder and independent Management IT Consultant working on the development of an IoT device - Eggy.</br>
    </br>
    Apr 2016 <-> Dec 2016</br>
    &nbsp;&nbsp;&nbsp;Business Consultant at <a href="https://www.infosistema.com/" target="_blank">Infosistema</a>. Performed business analysis and project management tasks in the implementation of a new Web Portal - <a href="https://www.medis.pt/" target="_blank">Medis</a> - for the Portuguese biggest private insurance company - <a href="https://www.ocidental.pt/" target="_blank">Ocidental</a>.</br>  
    </br>
    Jan 2017 <-> Aug 2017</br>
    &nbsp;&nbsp;&nbsp;Founder and Web Developer of <a href="https://streettelling.com/" target="_blank">Streettelling</a>.A business focused on delivering marketing communication, social network presence and Web development for small local businesses.</br>
    </br>
    Jul 2017 <-> Feb 2018</br>
    &nbsp;&nbsp;&nbsp;Javascript developer at <a href="https://www.viaconsultingway.com/" target="_blank">Via Consulting S.A.</a>. Responsible for the Front-end architecture of in-house solutions.</br>
    </br>
    Jan 2018 <-> Aug 2019</br>
    &nbsp;&nbsp;&nbsp;Senior Javascript developer at SpotQA. Lead FE developer for automated QA solutions - <a href="https://www.virtuoso.qa/" target="_blank">Virtuoso</a> - delivered under a SAsS business model.</br>
    </br>
    Oct 2019 <-> May 2021</br>
    &nbsp;&nbsp;&nbsp;FE Developer at <a href="https://www.mercedes-benz.io/" target="_blank">Mercedes-Benz.io</a>. Senior Front-end developer responsible for implementation and maintenance of Mercedes-Benz online marketplaces in the European Market.</br>
    </br>
    Apr 2021 <-> present</br>
    &nbsp;&nbsp;&nbsp;FE Developer at <a href="https://www.salsify.com/" target="_blank">Salsify</a>. Senior Front-end developer - worked with multiple teams developing new features and maintaining the core pages of the webapp. Developed new features around product data regarding product variant options, static sites generation from product data and product enhanced content for publishing in third party retailer platforms. Currently integrated in the core team that maintains PIM pages.</br>
    </br>`,
    tech = `</br>
    Languages -> proficiency [ 1 2 3 4 5 ]</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 <b class="line__prof--value">4</b> 5]</span> Javascript </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 <b class="line__prof--value">4</b> 5]</span> Typescript </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 <b class="line__prof--value">4</b> 5]</span> HTML & CSS </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <b class="line__prof--value">3</b> 4 5]</span> File Transfer Protocols (json, xml, ...)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <b class="line__prof--value">3</b> 4 5]</span> SQL (MySQL) </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <b class="line__prof--value">3</b> 4 5]</span> Git </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <b class="line__prof--value">2</b> 3 4 5]</span> PHP </br>
    </br>
    </br>
    Frameworks -> proficiency [ 1 2 3 4 5 ]</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 4 <b class="line__prof--value">5</b>]</span> Vue.js (Javascript framework for the Web)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <b class="line__prof--value">3</b> 4 5]</span> Ember (Javascript framework for the Web)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 <b class="line__prof--value">4</b> 5]</span> Node.js (Javascript on OS context)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 <b class="line__prof--value">4</b> 5]</span> NPM (Node package manager)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 <b class="line__prof--value">4</b> 5]</span> Express (Server framework for Node)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 3 <b class="line__prof--value">4</b> 5]</span> LESS & SASS (CSS compilable notations)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <b class="line__prof--value">3</b> 4 5]</span> Puppeteer (Headless browser for automated tasks) </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <b class="line__prof--value">3</b> 4 5]</span> REST (http request/response principles) </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <b class="line__prof--value">2</b> 3 4 5]</span> BCrypt (Javascript password hashing)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <b class="line__prof--value">2</b> 3 4 5]</span> JsonWebToken (Javascript stateless user session management)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <b class="line__prof--value">2</b> 3 4 5]</span> Stripe (payment service for online stores/services) </br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <b class="line__prof--value">2</b> 3 4 5]</span> Codeigniter (PHP) </br>
    </br>
    Tools -> proficiency [ 1 2 3 ]</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <span class="line__prof--value">3</span> ]&nbsp;&nbsp;&nbsp;</span> Visual Studio Code (Code IDE)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <span class="line__prof--value">3</span> ]&nbsp;&nbsp;&nbsp;</span> Webstorm (Javascript IDE)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 2 <span class="line__prof--value">3</span> ]&nbsp;&nbsp;&nbsp;</span> Github Desktop (GUI for git)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <span class="line__prof--value">2</span> 3 ]&nbsp;&nbsp;&nbsp;</span> XAMPP (Out of the box PHP/MySQL Apache server)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <span class="line__prof--value">2</span> 3 ]&nbsp;&nbsp;&nbsp;</span> HeidiSQL (GUI for SQL databases)</br>
    &nbsp;&nbsp;&nbsp;<span class="line__prof">[ 1 <span class="line__prof--value">2</span> 3 ]&nbsp;&nbsp;&nbsp;</span> JIRA (Online platform for Agile management) </br>
    </br>
    </br>`,
    projects = `</br>
    Professional context</br>
    &nbsp;&nbsp;&nbsp; -> <a href="https://shop.mercedes-benz.com/pt-pt/mrktplc/vehicle/srp" target="_blank">Mercedes-Benz European Marketplaces</a> as Front-end Developer at Mercedes-Benz.io</br>
    &nbsp;&nbsp;&nbsp; -> <a href="https://www.virtuoso.qa/" target="_blank">Virtuoso</a> as Front-end developer at SpotQA</br>
    &nbsp;&nbsp;&nbsp; -> <a href="https://www.medis.pt/" target="_blank">Medis</a> as Project Manager at Ocidental (via Infosistema)</br>
    </br>
    Freelance context</br>
    &nbsp;&nbsp;&nbsp; -> Airport baggage management software Front-end for <a href="https://www.ool.pt/index.html" target="_blank">Out Of Limits</a> [not disclosed]</br>
    &nbsp;&nbsp;&nbsp; -> Airport restroom management software Front-end for <a href="https://www.ool.pt/index.html" target="_blank">Out Of Limits</a> [not disclosed]</br>
    &nbsp;&nbsp;&nbsp; -> Public tender and contract web scrapper for <a href="https://pt.vortal.biz/" target="_blank">Vortal</a> [not disclosed]</br>
    &nbsp;&nbsp;&nbsp; -> Full stack development of <a href="https://www.jandaia.pt/#/home" target="_blank">Jandaia</a> shoes and accessories  online store.</br>
    &nbsp;&nbsp;&nbsp; -> <a href="https://www.dezprauma.com/" target="_blank">Dez Prá Uma</a> food take-away online store</br>
    </br>
    Didactic context</br>
    &nbsp;&nbsp;&nbsp; -> Grid bot algorithm for trading including FE dashboard for management and reporting [not disclosed]</br>
    &nbsp;&nbsp;&nbsp; -> WhatsApp message API available at <a href="https://github.com/JFCodes/WhatsAppJS" target="_blank">GitHub JFCodes</a></br>
    &nbsp;&nbsp;&nbsp; -> Solutions for <a href="https://projecteuler.net/" target="_blank">Project Euler</a> problems at <a href="https://github.com/JFCodes/ProjectEulerTS" target="_blank">GitHub JFCodes</a></br>
    &nbsp;&nbsp;&nbsp; -> The game 2048 running on Node CLI at <a href="https://github.com/JFCodes/2048-Node-CLI" target="_blank">GitHub JFCodes</a></br>
    &nbsp;&nbsp;&nbsp; -> A website that scans and solves SUDOKU problems at <a href="https://github.com/JFCodes/sudoker" target="_blank">GitHub JFCodes</a></br>
    &nbsp;&nbsp;&nbsp; -> An online radio of early 2000's top hits <a href="https://oh-one-radio.herokuapp.com/" target="_blank">oh-one radio</a>, deployed online</br>
    </br>`,
    hobbies = `</br>
    My main hobby revolves around Motorcycles and Scuba Diving.</br>
    I own a Honda CB650R. This is my commute transportation, my COVID confinement mental health check and my weekend tour company.
    I also own a Honda 600F Sport that is modified and track ready. It's not road legal and you would need to go on Autodromo do Estoril to see me drag my kneed on corners and speeding in excess of 240 kph on the straights on top of this gorgeous machine :)</br>
    </br>
    I took my scuba diving certification for 'open water' and 'advanced scuba diving' in the summer of 2022. I have more then 40 dives in Sesimbra Portugal. The ideal location to observe maritime plants and animals. I also dove 2 times in Hawaii and had the best time diving in the coral reff with see turtles and a plenitude of different fishes of various colors and sizes.
    </br>
    Keeping the subject of a motor-head persona, I am a fan and follower of Formula 1 and MotoGP Championships.</br>
    </br>
    I also love photography. I am a complete noob, but frequently travel with my camera and I am always on the lookout for a good picture. Regardless if with my phone or my Canon 800D, I must have shot Cascais sunsets hundreds of times.</br>
    </br>
    Besides those, my interests are mundane and common: going to the beach whenever is sunny, watching movies and tv series and occasionally travel to the Algarve and Alentejo always riding/driving down through our beautiful Costa Vicentina.
    </br>`,
}
