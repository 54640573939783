import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class AppMixin extends Vue {
    appOnOpen?: () => void
    appOnClose?: () => void

    @Prop({ required: true }) isOpen!: boolean
    @Prop({ required: true }) appIndex!: number

    closed: boolean = true
    delayedClose: boolean = true

    mixinOpen(): void {
        this.delayedClose = false
        this.$nextTick(() => {
            this.closed = false
            if (this.appOnOpen) this.appOnOpen()
        })
    }

    mixinClose(): void {
        this.closed = true
        if (this.appOnClose) this.appOnClose()
        setTimeout(() => { this.delayedClose = true }, 300)
    }

    close():void {
        this.$emit('close')
    }

    @Watch('isOpen', { immediate: true })
    onIsOpenChange(isOpen: boolean): void {
        isOpen ? this.mixinOpen() : this.mixinClose()
    }
}