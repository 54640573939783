import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// TODO: more info on the first line. Name, age,
// TODO: command who am I that runs on init
// TODO: easter eggs: sudo command, su/cd/mkdir/... always reports as unauthorized
// TODO: command --version
