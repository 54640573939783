















































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import axios from 'axios'
import AppMixin from "./_AppMixin";
// Components
import AppTemplate from './_AppTemplate.vue'

@Component({
  components: {
    AppTemplate
  }
})
export default class EmailApp extends Mixins(AppMixin) {
  emailFrom: string = ''
  emailSubject: string = ''
  emailBody: string = ''
  isRequesting: boolean = false
  feedBackMessage: string = ''
  feedBackError: boolean = false

  get canSend(): boolean {
    if (!this.emailFrom) return false
    if (!this.emailSubject) return false
    if (!this.emailBody) return false

    return true
  }

  sendEmail () {
    if (!this.canSend) return

    const bodyData = {
      from: this.emailFrom,
      subject: this.emailSubject,
      content: this.emailBody
    }

    this.isRequesting = true
    this.feedBackError = false
    this.feedBackMessage = 'sending email...'
    axios.post(`api/sendmail`, bodyData)
      .then(() => {
        this.feedBackMessage = 'Email sent!!!'
        this.emailFrom = ''
        this.emailSubject = ''
        this.emailBody = ''
      })
      .catch((error) => {
        if (error.response && error.response.data.reason) {
          this.feedBackMessage = `could not send email (${error.response.data.reason}) :/`
        } else {
          this.feedBackMessage = 'could not send email :/'
        }
        this.feedBackError = true
      })
      .finally(() => {
        this.isRequesting = false
      })
  }
}
