import Vue from 'vue'
import Vuex from 'vuex'
import { COMMAND_TEXTS } from '../components/commands/commandTexts'

Vue.use(Vuex)

interface LINE {
  carret: boolean
  type: 'log' | 'error' | 'warning' | 'success'
  text: string
}

const FIRST_LINE: LINE = {
  carret: false,
  type:'warning',
  text: COMMAND_TEXTS.firstLine
}

export default new Vuex.Store({
  state: {
    lines: []
  },
  mutations: {
    ADD_FIRST_LINES(state) {
      Vue.set(state.lines, state.lines.length, FIRST_LINE)
    },
    ADD_LINE(state, line: LINE) {
      Vue.set(state.lines, state.lines.length, line)
    },
    CLEAR (state) {
      state.lines = []
    }
  },
  actions: { },
  modules: { }
})
