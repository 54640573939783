








































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import AppMixin from "./_AppMixin";
// Components
import AppTemplate from './_AppTemplate.vue'
import CommandsIndex from "../commands/index.vue";

const SPACE_ENTITY = "&nbsp;";

@Component({
  components: {
    AppTemplate,
    CommandsIndex
  }
})
export default class TerminalApp extends Mixins(AppMixin) {
  $refs!: {
    terminalContent: HTMLElement
    inputLine: HTMLInputElement
  }
  isInputing: boolean = false;
  carretShow = false;
  currentInput: string = "";
  sendCommand: string = "";
  history: string[] = [];
  historyIndex: number = -1;

  appOnOpen = () => {
    this.$store.commit("ADD_FIRST_LINES");
  }
  appOnClose = () => {
    this.$store.commit("CLEAR");
  }

  get lines() {
    return this.$store.state.lines;
  }

  addChar(event: any): void {
    // Detect backspace/delete and handle that on the other function
    const DELETE_KEYS = ["Backspace", "Delete"];
    if (DELETE_KEYS.indexOf(event.key) !== -1) {
      this.removeChar();
      return;
    }

    switch (event.key) {
      case 'Enter':
        this.execute();
        return;
      case 'ArrowUp':
        this.navigateHistory();
        return;
      case 'ArrowDown':
        this.navigateHistory(true);
        return;
    }

    this.historyIndex = -1;
    let nextValue = "";
    
    this.$refs.inputLine as HTMLInputElement
    switch (this.$refs.inputLine.value) {
      case " ":
        nextValue = SPACE_ENTITY;
        break;
      default:
        // @ts-ignore
        nextValue = this.$refs.inputLine.value;
    }

    this.currentInput += nextValue;
    // @ts-ignore
    this.$refs.inputLine.value = "";
  }

  removeChar(): void {
    let lastIndex: number = this.currentInput.length - 1;
    if (lastIndex === -1) return;

    // Detect special char space SPACE_ENTITY
    // can only have a space if lenght is greater then 5
    const lastChars = this.currentInput.substr(SPACE_ENTITY.length * -1);
    if (lastChars === SPACE_ENTITY) {
      lastIndex -= SPACE_ENTITY.length - 1;
    }

    this.currentInput = this.currentInput.substring(0, lastIndex);
  }

  navigateHistory(isDown: boolean = false): void {
    // No history, no navigation
    if (this.history.length === 0) return;

    if (isDown) {
      // Reached last item, no more navigation
      if (this.historyIndex === this.history.length - 1) return;
      // No navigation yet, cannot go further
      if (this.historyIndex === -1) return;
      this.historyIndex++;
    } else {
      // Reached first item, no more navigation
      if (this.historyIndex === 0) return;
      // First navigation, point to history last item + 1
      if (this.historyIndex === -1) this.historyIndex = this.history.length;
      this.historyIndex--;
    }

    this.currentInput = this.history[this.historyIndex];
  }

  execute(): void {
    console.log('execute')
    const trimCommand = this.currentInput.trim();
    if (!trimCommand) {
      this.$store.commit("ADD_LINE", {
        carret: false,
        type: "log",
        text: `C:/jfcodes>`,
      });
      return;
    }

    if (trimCommand !== this.history[this.history.length - 1]) this.history.push(trimCommand);
    this.sendCommand = trimCommand;
    this.historyIndex = -1;
  }

  done(): void {
    this.sendCommand = "";
    this.currentInput = "";
    this.$nextTick(() => {
      this.$refs.terminalContent.scrollTo(0, this.$refs.terminalContent.scrollHeight);
    });
  }

  copy(): void {
    navigator.clipboard.writeText(this.$refs.terminalContent.textContent || "");
  }

  mounted() {
    if (this.$refs.inputLine) this.$refs.inputLine.focus();
    setInterval(() => {
      this.isInputing
        ? (this.carretShow = !this.carretShow)
        : (this.carretShow = false);
    }, 500);
  }
}
